var ROOT_URL = 'https://gatewayuat.verisk.com/rating/au/travel/';
var API_VERSION = 'v2';
var HOSTPAGE_ROOT_URL = '/quotes-steps-two';
var RESULT_DESTINATION_VAR = 'hdResult';
var USER_SETTINGS = {
    "$G_Age": 0,
    "$G_TripDuration": 0,
    "$G_LeadTime": 0,
    "$G_IsWinterSport": 0,
    "$G_IsAnnual": 0,
    "$G_DirectlyLinked": false,
    "$G_IndirectlyLinked": false,
    "$G_Regions": "",
    "$G_CancellationCostId": 0,
    "$G_Username": ""
};

var AUTH_DATA = {
    uri: "",
    data: {
        grant_type: ""
    }
};
var EVENT_LISTENERS = {
    onFinish: function (eventType, result) {
        window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(eventType, result, "success"); }); 
        return result;
    },
    onError: function (eventType, errors) {
        window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(eventType, errors, "error"); }); 
        return errors;
    }
};

